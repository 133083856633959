<template>
  <div>
    <a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" scrollToFirstError
            @finish="getByVerificationCode">
      <a-row justify="center">
        <a-form-item class="ui-form__item" label="演出票核销码" name="verificationCode" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input ref="codeInput" v-model:value="formState.verificationCode" placeholder="请输入或扫描核销码"/>
        </a-form-item>
        <a-button type="primary" html-type="submit">查询</a-button>
        <a-button style="margin-left: 10px" @click="reset">重置</a-button>
      </a-row>
    </a-form>
    <h4 style="margin-top: 20px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 10px;font-size: 16px;" v-if="list.length">核销演出</h4>
    <a-spin :spinning="loading">
      <div v-if="list.length">
        <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="false" :scroll="{ x: 1000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'showTime'">
				<div v-if="record.performDto">
					<div>{{ transDateTime(record.performDto.showStartTime) }}</div>
					至
					<div>{{ transDateTime(record.performDto.showEndTime) }}</div>
				</div>
              <!-- {{ record.skuName }} -->
            </template>
            <template v-if="column.key == 'afterSaleStatus'">
              <div v-if="record.afterSaleStatus === 0">无售后</div>
              <div v-if="record.afterSaleStatus === 1">售后中</div>
              <div v-if="record.afterSaleStatus === 2">完成售后</div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

    <h4 style="margin-top: 20px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 10px;font-size: 16px;" v-if="list.length">核销信息</h4>
    <a-spin :spinning="loading">
      <div v-if="list.length">
        <a-table style="margin-top: 10px;" :columns="infoColumns" :dataSource="list" rowKey="id" :pagination="false" :scroll="{ x: 1000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'userInfo'">
              <div>用户名称：{{record.nickName || '-'}} </div>
              <div>联系电话：{{record.phone || '-'}} </div>
            </template>
            <template v-if="column.key == 'status'">
              <div>
				  {{ transTicketStatus(record.performUserTicket.status) }}
			  </div>
            </template>
			<template v-if="column.key === 'viewer'">
				<div>
					<div>观演人名称：{{ record.userViewer.fullName }}</div>
					<div>证件类型：{{ ['身份证', '港澳台居民居住证', '港澳居民来往内地通行证', '台湾居民来往内地通行证', '护照', '外国人永久居留证', '军人证'][record.userViewer.idType - 1] }}</div>
					<div>证件号：{{ record.userViewer.cardNumber }}</div>
				</div>
			</template>
            <template v-if="column.key == 'signTime'">
				<div style="color: red;font-size: 16px;">
					{{ transDateTime(record.performUserTicket.verificationTime) }}
				</div>
            </template>
            <template v-else-if="column.key === 'action'">
              <div v-permission="['verify_sale_manage_perform_confirm']" @click="onVerification(record)" v-if="record.performUserTicket.status === 0">
                <!-- 需要二次确认 -->
                <a>核销</a>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getByVerificationCode, verificationOrderBase} from "@/service/modules/order";
export default {
  components: {},
  data() {
    return {
      loading: false,
      formState: {},
      searchData: [],
      list: [],
      infoList: [],
      infoColumns: [{
        title: '核销码',
        dataIndex: ['performUserTicket', 'ticketNumber']
      }, {
        title: '用户信息',
        key: 'userInfo'
      }, {
		  title: '观演人',
		  key: 'viewer'
	  }, {
        title: '核销状态',
        key: 'status'
      }, {
        title: '核销时间',
        key: 'signTime'
      }, {
        title: '核销人',
        dataIndex: ['performUserTicket', 'verificationUserName']
      }, {
        title: '操作',
        key: 'action',
        width: 150,
        align: 'center',
        fixed: 'right'
      }],
      columns: [{
        title: '演出名称',
        dataIndex: ['performDto', 'name']
      }, {
        title: '演出场次',
        key: 'showTime'
      }, {
		  title: '演出场地',
		  dataIndex: ['performDto', 'siteName']
	  }, {
        title: '票档',
		dataIndex: ['performDto', 'ticketName']
      }, {
		  title: '座位编码',
		  dataIndex: ['performSeat', 'seatCode']
	  }, {
		  title: '座位号',
		  dataIndex: ['performSeat', 'seatName']
	  }],
    }
  },
  created() {},
  methods: {
    async getByVerificationCode() {
      this.loading = true;
      try {
        let ret = await getByVerificationCode({
          verificationCode: this.formState.verificationCode,
          saleType: 4
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = [ret.data];
          // this.infoList = [];
          // this.infoList.push(ret.data);
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onVerification(item) {
      this.$confirm({
        title: '提示',
        content: '确定完成核销吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let res = await verificationOrderBase({
              orderId: item.id,
			  verificationCode: item.performUserTicket.ticketNumber
            });
            this.loading = false;
            if (res.code === 200) {
              this.$message.success('核销成功');
              this.getByVerificationCode();
            }
          } catch (error) {
            this.loading = false;
          }
        }
      })
    },
	transTicketStatus(status) {
		if (status === 0) {
			return '待使用';
		} else if (status === 1) {
			return '已使用';
		} else if (status === 2) {
			return '已过期';
		} else if (status === 3) {
			return '已转赠'
		} else if (status === 4) {
			return '已售后'
		} else if (status === 5) {
			return '已支付';
		}
	},
    reset() {
      this.$refs.form.resetFields();
      this.list = [];
      this.infoList = [];
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  width: 500px;
  margin-right: 30px;
}
</style>
